<template>
  <!-- 添加角色页面 -->
  <div class="positionT0L0">
    <p class="fw600 marginB20">基本信息</p>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="{ span: 3 }" :wrapper-col="{ span: 6 }">
      <a-form-model-item label="角色名称" prop="roleName" class="por">
        <a-input v-model="form.roleName" :maxLength="32" placeholder="请输入角色名称">
          <div slot="addonAfter">{{ form.roleName.length }}/32</div>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="显示状态" class="por">
        <a-switch v-model="form.isDisplay" />
      </a-form-model-item>
      <a-form-model-item label="是否专属代理商" class="por" v-if="this.$route.params.id == '88'">
        <a-radio-group v-model="form.isAgent" name="radioGroup">
          <a-radio :value="0">否</a-radio>
          <a-radio :value="1">是</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="代理等级" prop="levelNo" class="por" v-if="form.isAgent && this.$route.params.id == '88'">
        <a-select v-model="form.levelNo" placeholder="请选择代理等级">
          <a-select-option v-for="item in levelNoArr" :key="item.levelNo">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="排序" prop="sort" class="por">
        <a-input v-model="form.sort" placeholder="请输入排序编号">
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="角色描述" prop="remark">
        <a-textarea placeholder="请输入角色描述" v-model="form.remark" :rows="5"/>
        <span class="form_span">{{ form.remark.length }}/300</span>
      </a-form-model-item>
    </a-form-model>

	<!-- 树形结构 -->
    <p class="fw600">权限设置</p>
    <template>
        <a-tree :tree-data="treeData" :replace-fields="replaceFields"  @check="onCheck" :checkable="true" :checkedKeys="checkedKeys"/>
    </template>

	<!-- 操作按钮 -->
    <FooterToolBar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="() => $router.go(-1)">返回</a-button>
      <a-button type="primary" @click="keepClick" :disabled="isDisabled">保存</a-button>
    </FooterToolBar>
  </div>
</template>
<script>
import FooterToolBar from "@/components/FooterToolbar";
import { baseMixin } from "@/store/app-mixin";
import { message } from "ant-design-vue";
import { TreeListApi, RoleAddApi, RoleUpdateApi, RoleDetailApi } from "@/request/api/rolePower";
import { AgentLevel } from "@/request/api/agenManage";
export default {
	mixins: [baseMixin],
	components: { FooterToolBar },
	created() {
		this.agentlevel()
        let id = this.$route.params.id;
        this.queryId = this.$route.params.id;
		// 获取树形图的列表的请求
		TreeListApi().then(({ code, data }) => {
            if (code === 200) {
                let newDatas = this.transferKey(data);
                this.treeData = newDatas;
                if (id !== "88") {
                    // 获取角色详情
                    RoleDetailApi({ roleNo: id }).then(({ code, data }) => {
                        if (code == 200) {
                            this.form.roleName = data.roleInfo.roleName
                            this.form.isDisplay = data.roleInfo.displayStatus == 1 ? true : false
                            this.form.isAgent = data.roleInfo.isSuper*1
                            this.form.sort = data.roleInfo.sort
                            this.form.levelNo = data.roleInfo.levleNo
                            this.form.remark = data.roleInfo.remark
                            this.form.roleMenus = data.roleMenus
                            let list1 = this.familyTree(this.treeData);
                            // 去除两个数组中相同的项
                            let list = data.roleMenus.filter((key) => {
                                return list1.indexOf(key) !== 1;
                            });
                            let add = list.filter(items => {
                                if (!list1.includes(items)) return items;
                            })
                            this.checkedKeys = add;
                        }
                    });
                }
            }
		});
	},
	data() {
        return {
            queryId:'',
            replaceFields: {
                title: "name",
            },
			isDisabled: false, // 保存的按钮是否禁用
			form: {
				roleName: "",
				sort: 0,
				isDisplay: true,
				isAgent:1,
				levelNo: undefined,
				remark: "",
                roleMenus:[],
			},
			rules: {
				roleName: [
				{
					required: true,
					message: "输入的内容不能为空",
					trigger: "blur",
				},
				{
					max: 32,
					message: "长度最长为32个字",
					trigger: "blur",
				},
				],
				remark: [
				{
					min: 0,
					max: 300,
					message: "长度为5~300个",
					trigger: "blur",
				},
				],
			},

			// 权限等级数组
			levelNoArr: [],

			// 树形选中的ID数组
            checkedKeys: [],
            checkedKeysTow:[], //合并后的数组

			//树形接口的数据
			treeData: [],
		};
	},
	methods: {
		// 树形选择框的回调
        onCheck (checkKeys, checked, e) {
            this.checkedKeys = checkKeys
            let halfCheckedKeys = checked.halfCheckedKeys;
            if (this.checkedKeys.length>0 && halfCheckedKeys.length>0) {
                this.checkedKeysTow =  this.checkedKeys.concat(halfCheckedKeys)
            } else {
                this.checkedKeysTow =  checkKeys
            }
		},
		// 保存按钮
		keepClick() {
			this.isDisabled = true;
			setTimeout(() => {
				this.isDisabled = false;
			}, 2000);
			let id = this.$route.params.id;
			if (id == "88") {
				// 添加角色接口
                let obj = {
                    roleName: this.form.roleName,
					sort: this.form.sort*1,
					isDisplay: this.form.isDisplay ? 1 : 0,
					isAgent:this.form.isAgent,
					levelNo: this.form.levelNo,
					remark: this.form.remark,
					menuNos: this.checkedKeysTow,
                }
				RoleAddApi(obj).then(({ code }) => {
					if (code == 200) {
						message.success("保存成功");
						this.$router.push("/rolePower/roleManage");
					}
				});
			} else {
				// 更新角色
                let obj = {
                    roleNo: id,
					roleName: this.form.roleName,
					sort: this.form.sort*1,
					isDisplay: this.form.isDisplay ? 1 : 0,
					isAgent:this.form.isAgent,
					levelNo: this.form.levelNo,
					remark: this.form.remark,
					menuNos: this.checkedKeysTow.length>0 ? this.checkedKeysTow : this.form.roleMenus,
                }
				RoleUpdateApi(obj).then(({ code }) => {
					if (code == 200) {
						message.success("保存成功");
						this.$router.push("/rolePower/roleManage");
					}
				});
			}
        },

		// 深度增加一个key字段
		transferKey(array) {
			for (var i = 0; i < array.length; i++) {
				array[i].key = array[i].menuNo;
				if (array[i].children && array[i].children.length > 0) {
					this.transferKey(array[i].children);
				}
			}
			return array;
		},

        // 递归-树形数组所有父级节点的id
        familyTree (arr) {
            let temp = [];
            let forFn = function (list) {
                for (let i = 0; i < list.length; i++) {
                    let item = list[i];
                    if (item.children.length > 0) {
                        forFn(item.children);
                        temp.push(item.menuNo);
                    }
                }
            };
            forFn(arr);
            return temp;
        },

		// 代理等级接口
		agentlevel() {
			AgentLevel({}).then(({ data,code }) => {
				if (code == 200) {
					this.levelNoArr = data
				}
			});
		}
	},
};
</script>

<style lang="less" scoped>
/deep/.ant-btn-lg {
  margin-right: 60px;
}
/deep/.ant-input {
  resize: none;
}
</style>
